<template>
  <!-- 自动掉落件报工 -->
  <div class="autoDropReporting_content">
    <van-nav-bar left-text="自动掉落件报工"
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true">
    </van-nav-bar>
    <div class="content">
      <div class="date"
           @click="checkExDate('today')">
        <i class="fa fa-calendar"></i>
        <span> {{ yymmdd?yymmdd:'请选择' }}</span>
        <div class="faRight">
          <i class="fa fa-sort-desc"></i>
        </div>
      </div>
      <div class="table_box"
           style="overflow: auto;">
        <table ref="table"
               class="table">
          <!-- 表头 -->
          <tr class="top">
            <th v-for="(item_th, index_th) in autoThList"
                :key="index_th">
              <div class="title"
                   :style="item_th.gridwith ? { width: item_th.gridwith * 1.5 + 'px' } : 'min-width:90px'">
                {{ item_th.chnname }}
              </div>
            </th>
          </tr>
          <!--展示列表数据 -->
          <tr class="cont"
              v-for="(item_tr, index_tr) in autoTableData"
              :key="index_tr">
            <td v-for="(item_td, index_td) in autoThList"
                :key="index_td">
              <div class="comm_box">
                <div class="boolean_box"
                     v-if="item_td.datatype == 'checkbox'">
                  <van-checkbox v-model="item_tr[item_td.fieldname]"></van-checkbox>
                </div>
                <div class="input_box"
                     v-else-if="item_td.datatype == 'num'">
                  <van-field v-model="item_tr[item_td.fieldname]"
                             type="number"
                             :placeholder="item_td.chnname" />
                </div>
                <div class="input_box"
                     v-else-if="item_td.datatype == 'select'">
                  <van-field v-model="item_tr[item_td.fieldname]"
                             readonly
                             right-icon="arrow-down"
                             :placeholder="item_td.chnname"
                             @click="checkEmp(item_tr)" />
                </div>
                <div v-else>{{ item_tr[item_td.fieldname] }}</div>
              </div>
            </td>
          </tr>
          <tr class="nodata"
              v-if="autoTableData.length == 0">
            <td align="center"
                :colspan="autoThList.length+1">暂无数据</td>
          </tr>
        </table>
      </div>
      <div class="footer">
        <div class="f_btn"
             @click="checkFoot('no')"
             style="border-right: 1px solid #d2d2d2;">
          <!-- <img :src="require('@/assets/img/job_booking04.png')"
               alt /> -->
          <van-icon name="clear"
                    color="#ff976a" />
          <div>取消</div>
        </div>
        <div class="f_btn"
             @click="checkFoot()">
          <!-- <img :src="require('@/assets/img/job_booking05.png')"
               alt /> -->
          <van-icon name="checked"
                    color="#1989fa" />
          <div>确认</div>
        </div>
      </div>
    </div>
    <!-- 日期日历弹窗 -->
    <van-calendar v-model="showCalendar"
                  :type="'single'"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onConfirmCal" />
    <!-- 选择人员树弹窗 -->
    <PopTree ref="popTreeNode"
             :whichTree="'employee'"
             module="814"
             :multiseltree="false"
             @treeSelectValEvent="treeSelectValEvent"></PopTree>
  </div>

</template>

<script>
import { Toast } from "vant";
import PopTree from "@components/PopTree/index.vue";
import { getBgTempToolsList, saveBgTempToolsList } from '@api/wxjj.js'
export default {
  components: {
    PopTree
  },
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
    return {
      userInfo,
      yymmdd: "",
      showCalendar: false,
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      autoThList: [
        {
          fieldname: "processname",
          chnname: "工序名称",
          gridwith: 60,
          datatype: 'str'
        },
        {
          fieldname: "empname",
          chnname: "线长",
          gridwith: 60,
          datatype: 'select'
        },
        {
          fieldname: "bgnum",
          chnname: "报工数量",
          gridwith: 50,
          datatype: 'num'
        }
      ],
      autoTableData: [],
      checkRow: {}
    }
  },
  created () {
    this.yymmdd = this.formatDate(new Date(), 'yyyy-MM-dd')
    this.getBgTempToolsListData()
  },
  methods: {
    onClickLeft () {
      this.$router.push('/jobBooking')
    },
    // 调起日历弹窗方法
    checkExDate () {
      this.showCalendar = true;
    },
    getBgTempToolsListData () {
      getBgTempToolsList({
        username: this.userInfo.username,
        yymmdd: this.yymmdd
      }).then(res => {
        let ary = res.data
        ary.forEach(ele => {
          ele.empid = ''
          ele.empname = ''
          ele.bgnum = null
        });
        this.autoTableData = ary
      })
    },
    // 日期选择回调
    onConfirmCal (date) {
      this.yymmdd = this.formatDate(date, 'yyyy-MM-dd')
      // this.getBggjListData()
      // this.getBggxListData()
      this.showCalendar = false;
      this.getBgTempToolsListData()
    },
    // 选择线长
    checkEmp (item_tr) {
      this.checkRow = item_tr
      this.showEmpTreePop()
    },
    // 调起树
    showEmpTreePop () {
      this.$refs.popTreeNode.module = "814";
      this.$refs.popTreeNode.treename = "glsxztree";
      this.$refs.popTreeNode.editstate = 2;
      this.$refs.popTreeNode.showEmpTreePop('选择线长');
    },
    // 树回调
    treeSelectValEvent (data) {
      this.checkRow.empname = data.empname;
      this.checkRow.empid = data.empid;
    },
    // 底部按钮切换
    checkFoot (val) {
      if (val == 'no')
      { // 取消 
        this.onClickLeft()
      } else
      { // 确认
        if (!this.yymmdd)
        {
          Toast.fail('请选择日期！')
          return
        }
        saveBgTempToolsList({
          username: this.userInfo.username,
          yymmdd: this.yymmdd,
          data: this.autoTableData
        }).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Toast.success('保存成功！')
            this.onClickLeft()
          }
        })
      }
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)
      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')
        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else
        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
          // + ':' + this.add0(s)
        }
      } else
      {
        return ''
      }
    },
  }

}
</script>

<style lang="less">
.autoDropReporting_content {
  height: 100vh;
  background-image: url("~@/assets/img/job_booking01.png");
  overflow: hidden;
  background-color: #eaedef;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 1.22667rem;
  .van-nav-bar {
    height: 92px;
    background: #bcdfff;
    .van-nav-bar__left {
      .van-icon {
        color: #333;
        font-size: 0.5rem;
      }
      .van-nav-bar__text {
        color: #333;
        font-size: 36px;
        font-weight: bold;
      }
    }
    .van-nav-bar__title {
      color: #333;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    height: calc(100% - 212px);
    margin-top: 92px;
    overflow-y: auto;
    .date {
      font-size: 28px;
      margin: 20px 0 20px 20px;
      padding: 10px;
      display: flex;
      align-items: center;
      width: 320px;
      border: 1px solid #d2d2d2;
      border-radius: 10px;
      background: #fff;
      span {
        margin-left: 20px;
        width: 200px;
      }
      .fa-calendar {
        margin-right: 6px;
      }
      .faRight {
        i {
          margin-left: 6px;
          margin-bottom: 16px;
        }
      }
    }
    .table_box {
      .table {
        width: 100%;
        border-spacing: 0px;
        tr {
          td:nth-child(1) {
            border-left: 2px solid #dcdfe6;
          }
          th,
          td {
            padding: 6px 10px;
            text-align: center;
            border-bottom: 2px solid #dcdfe6;
            border-right: 2px solid #dcdfe6;
            .comm_box {
              display: flex;
              justify-content: center;
              .boolean_box {
                .van-checkbox {
                  justify-content: center;
                  /deep/.van-checkbox__icon--checked {
                    .van-icon {
                      color: #fff !important;
                      background-color: #1989fa !important;
                      border-color: #1989fa !important;
                    }
                  }
                  /deep/.van-checkbox__icon--disabled .van-icon {
                    background-color: #fff;
                  }
                }
              }
              .input_box {
                width: 180px;
                .van-cell {
                  font-size: 24px;
                  border: 2px solid #d2d2d2;
                  border-radius: 10px;
                  padding: 8px 16px;
                }
              }
            }
          }
        }
        .top {
          position: sticky;
          top: 0;
          font-size: 28px;
          background: #e9f3fd;
          height: 64px;
          th:nth-child(1) {
            border-left: 2px solid #dcdfe6;
          }
          th {
            border-top: 2px solid #dcdfe6;
          }
        }
        .cont {
          height: 64px;
          font-size: 24px;
        }
        .cont:nth-child(2n-1) {
          background: #f5f5f5;
        }
        .nodata {
          td {
            font-size: 28px;
          }
        }
      }
    }
    .footer {
      height: 120px;
      background: #fff;
      width: 100%;
      position: absolute;
      bottom: 0;
      font-size: 28px;
      display: flex;
      .f_btn {
        flex: 1;
        text-align: center;
        padding-top: 10px;
        .van-icon {
          font-size: 60px;
          // height: 60px;
        }
      }
    }
  }
}
</style>